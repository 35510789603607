const Bismillah = () => {
  return (
    <div
      style={{
        display: "flex",
        justifySelf: "center",
        fontFamily: "'Mada', sans-serif",
        boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
      }}
    >
      <span>بِسْمِ اللهِ الرَّحْمنِ الرَّحِيمِ</span>
    </div>
  );
};
export default Bismillah;
